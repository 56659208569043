<!-- Name -->
<script lang="ts">
export default {
  name: 'app-action-sticky'
};
</script>

<script lang="ts" setup>
import { useModalStore } from '~/store/modal';

const modalStore = useModalStore();
const loginModal = resolveComponent('ui/modals/app-login');
const route=useRoute();
const { showHideModal } = modalStore;
const { isAuthenticated } = useSanctumAuth();

const showModalLogin= () => {
  const payload = {
  showModal: true,
  component: loginModal,
  size: "full-mobile",
  props: {
      isBrand: false
  }
  };
  showHideModal(payload);

};

</script>

<template lang="pug">
aside.app-action-sticky(v-if="isAuthenticated")
  nuxt-link.app-action-sticky__item(:to="route.params.marca ? `/${route.params.marca}/panel/favoritos/`: '/panel/favoritos/'")
    span.icon-heart
  nuxt-link.app-action-sticky__item(:to="route.params.marca ? `/${route.params.marca}/panel/comparador/`: '/panel/comparador/'")
    span.icon-arrow-filter
aside.app-action-sticky(v-else)
  nuxt-link.app-action-sticky__item(@click.prevent="showModalLogin()")
    span.icon-heart
  nuxt-link.app-action-sticky__item(@click.prevent="showModalLogin()")
    span.icon-arrow-filter
  
</template>

<style lang="scss">
@import 'index';
</style> 